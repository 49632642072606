import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { styles } from "./LoginStyles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { withStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import { stockAPI } from "../../../../common/axiosInstance";
import { openAlertBox } from "../../../../common/AlertMessage/AlertMessage";
import { apiData } from "../../../../common/common-types";
import down_arrow from "../../../../assets/icons/submit_bill/toggle_closed_btn_icon.svg";
import mobileicon from "../../../../assets/icons/login/mobileicon.svg";
import { encrypt } from "../../../../crypto-helper";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      helperText: "",
      error: false,
      disabled: true,
      mobile: "",
    };
  }

  componentDidMount() {
    const { setRegVisitedflag } = this.props;
    setRegVisitedflag(false);
  }
  handleGetotp = (e) => {
    e.preventDefault();
    this.props.showLoader(true);
   const formData = {
     mobile_number: this.state.mobile.split(" ").join(""),
     platform: apiData.platform,
     transactionType: 201,
     merchant_id: apiData.merchant_id,
   };

   let encryptedPayload = {
     _k: encrypt(JSON.stringify(formData)),
   };

    stockAPI(encryptedPayload, "POST", "/getOtp", null, null, null, true)
      .then(async (res) => {
        // if success
        this.props.showLoader(false);
        if (res && res.statusCode === 100) {
          this.props.history.push({
            pathname: "/otpverification",
            state: {
              userid: res.response.id,
              usermobile: this.state.mobile.split(" ").join(""),
            },
          });
        } else if (res.status === "error" && res.error === "FAILED_MAX_LOGIN_ATTEMPT") {
          this.props.showLoader(false);
          openAlertBox(res.message, "error")
        } else if(res?.statusCode === 102 && res?.statusDescription?.toLowerCase() === "otp already sent." ) {
          this.props.showLoader(false);
          openAlertBox(res?.statusDescription || "Oops something went wrong!", "error");
          setTimeout(() => {
            this.props.history.push({
              pathname: "/otpverification",
              state: {
                userid: res?.response?.id,
                usermobile: this.state.mobile.split(" ").join(""),
              },
            });

          }, 3000);
        }else{
          this.props.showLoader(false);
          openAlertBox("Oops something went wrong!", "error");
        }
      })
      .catch(function (err) {
        this.props.showLoader(false);
        openAlertBox(err && err.message, "error");
      });
  };

  onChange(event) {
    const re = /(^[4-9][0-9\s\b]+$)/;
    if (
      event.target.value !== "3" &&
      event.target.value !== "2" &&
      event.target.value !== "1" &&
      event.target.value !== "0"
    ) {
      if (event.target.value === "" || re.test(event.target.value)) {
        this.setState({ helperText: "", error: false });
      } else {
        this.setState({
          helperText: "Please enter a valid 10 digit mobile number.",
          error: true,
          disabled: true,
        });
      }
    } else {
      this.setState({
        helperText: "Please enter a valid 10 digit mobile number.",
        error: true,
        disabled: true,
      });
    }

    if (re.test(event.target.value) && event.target.value.length === 12) {
      this.setState({ disabled: false });
    } else {
      this.setState({ disabled: true });
    }
    const input = event.target.value.replace(/\D/g, "").substring(0, 10); // First ten digits of input only
    const zip = input.substring(0, 4);
    const middle = input.substring(4, 7);
    const last = input.substring(7, 10);

    if (input.length > 7) {
      event.target.value = `${zip} ${middle} ${last}`;
    } else if (input.length > 4) {
      event.target.value = `${zip} ${middle}`;
    } else if (input.length > 0) {
      event.target.value = `${zip}`;
    }

    this.setState({ mobile: event.target.value });
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.main_root}>
      <div className={classes.wrapper}>
        {/* <span className={classes.icons + " " + classes.mobileiconCss} /> */}
        <img src={mobileicon} alt='login' className={classes.icons} />
        <Typography variant="h1" className={classes.heading}>
          Login with Mobile Number
        </Typography>

        <Typography variant="h3" className={classes.subHeading}>
          Please enter your 10 digit mobile number to receive an One Time Password
        </Typography>

        <form
          noValidate
          autoComplete="off"
          onSubmit={this.handleGetotp}
          style={{ position: "relative" }}
        >
          <div className={classes.formWrap}>
            <FormControl
              variant="outlined"
              className={classes.selDrop}
              disabled
            >
              <InputLabel id="demo-simple-select-outlined-label">
                +91
              </InputLabel>
              <Select
                className={classes.selDropLabel}
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                label=""
                value=""
                IconComponent={() => (
                  <img
                    className={classes.down_icon}
                    src={down_arrow}
                    alt=""
                    width="10"
                    height="6"
                  />
                )}
              ></Select>
            </FormControl>

            <TextField
              className={classes.mobileText}
              id="outlined-basic"
              placeholder="Enter your mobile number"
              label=""
              type="tel"
              onChange={this.onChange.bind(this)}
              value={this.state.mobile}
              error={this.state.error}
              inputProps={{
                maxLength: 12,
              }}
              variant="outlined"
            />
          </div>
          <span className={classes.errorMessage}>{this.state.helperText}</span>
          <div style={this.state.helperText ? { marginTop: "20px" } : {}}>
            <Button
              variant="contained"
              color="primary"
              className={classes.getotp}
              type="submit"
              disabled={this.state.disabled}
            >
              GET OTP
            </Button>
          </div>
        </form>
      </div>
      </div>
    );
  }
}

export default withRouter(withStyles(styles, { withTheme: true })(Login));
