import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import classnames from "classnames";
import { withStyles } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Search from "../../../../components/Search/Search";
import NoRecordFound from "../../../../components/NoRecordFound/NoRecordFound";
import { primaryOrange } from "../../../../components/UI/Theme";
import RedeemSubHeader from "../../../../components/RedeemSubHeader/RedeemSubHeader";
import { globalBackground } from "../../../../components/UI/Theme";
import default_product from "../../../../assets/images/product/default_product.svg";
const styles = (theme) => {
  return {
    fab1: {
      padding: "26px",
      height: "auto",
      [theme.breakpoints.down("641")]: {
        padding: "21px",
      },
      [theme.breakpoints.down("481")]: {
        padding: "15px",
      },
      [theme.breakpoints.down("381")]: {
        padding: "11px",
      },
      [theme.breakpoints.down("321")]: {
        padding: "9px",
      },
      // height: "100%",
      // overflowY: "auto",
      backgroundColor: theme.palette.common.globalBackground,
    },
    cardIcons: {
      width: "22px",
      height: "22px",
      marginLeft: "2rem",
      float: "right",
      [theme.breakpoints.down("xs")]: {
        width: "16px",
        height: "16px",
        marginLeft: "1rem",
      },
    },
    productImage: {
      height: "172px",
      width: "100%",
      objectFit: "contain",
      objectPosition: "center",
      [theme.breakpoints.down(641)]: {
        height: "152px",
      },
      [theme.breakpoints.down(481)]: {
        height: "114px",
      },
      [theme.breakpoints.down(430)]: {
        height: "95px",
      },
      [theme.breakpoints.down(380)]: {
        height: "88px",
      },
      [theme.breakpoints.down(361)]: {
        height: "88px",
      },
      [theme.breakpoints.down(321)]: {
        height: "80px",
      },
    },
    expiryDate: {
      width: "100%",
      // display: "flex",
      // justifyContent:'center',
      // alignItems:'center',
      // position: "absolute",
      // right: "0",
      // bottom: "52%",
      // backgroundColor: "#0000002e",
      color: "#454545",
      textAlign: "right",
      // fontStyle: "italic",
      margin: "0",
      height: "14px",
      lineHeight: "1.5",
      fontFamily: "Montserrat",
      fontSize: "14px",
      paddingRight: "0.625rem",
      paddingBottom: "2px",
      paddingTop: "2px",
      [theme.breakpoints.down(641)]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down(481)]: {
        fontSize: "11px",
        height: "13px",
      },
      [theme.breakpoints.down(421)]: {
        fontSize: "10.5px",
      },
      [theme.breakpoints.down(401)]: {
        fontSize: "10px",
      },
      [theme.breakpoints.down(381)]: {
        fontSize: "9.5px",
      },
      [theme.breakpoints.down(361)]: {
        fontSize: "9px",
        height: "11px",
      },
      [theme.breakpoints.down(321)]: {
        fontSize: "8px",
      },
    },
    location: {
      width: "100%",
      color: "#454545",
      textAlign: "left",
      margin: "0",
      height: "14px",
      lineHeight: "1.5",
      fontFamily: "Montserrat",
      fontSize: "14px",
      paddingBottom: "2px",
      paddingTop: "2px",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      "-webkit-line-clamp": 1,
      "-webkit-box-orient": "vertical",
      [theme.breakpoints.down(641)]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down(481)]: {
        fontSize: "11px",
        height: "13px",
      },
      [theme.breakpoints.down(421)]: {
        fontSize: "10.5px",
      },
      [theme.breakpoints.down(401)]: {
        fontSize: "10px",
      },
      [theme.breakpoints.down(381)]: {
        fontSize: "9.5px",
      },
      [theme.breakpoints.down(361)]: {
        fontSize: "9px",
        height: "11px",
      },
      [theme.breakpoints.down(321)]: {
        fontSize: "8px",
      },
    },
    nullExpiryDate: {
      display: "block",
      width: "100%",
      height: "15px",
      textAlign: "right",
      [theme.breakpoints.down(641)]: {
        height: "14px",
      },
      [theme.breakpoints.down(481)]: {
        fontSize: "10px",
        height: "11px",
      },
      [theme.breakpoints.down(421)]: {
        fontSize: "9.5px",
      },
      [theme.breakpoints.down(401)]: {
        fontSize: "9px",
      },
      [theme.breakpoints.down(381)]: {
        fontSize: "8.5px",
      },
      [theme.breakpoints.down(361)]: {
        fontSize: "8px",
      },
      [theme.breakpoints.down(321)]: {
        fontSize: "8px",
      },
    },
    pointsDisplay: {
      // marginLeft: "0.625rem",
      width: "100%",
      textAlign: "left",
      display: "flex",
      justifyContent: "flex-start",
      // height: "78px",
      marginTop: "20px",
      [theme.breakpoints.down(641)]: {
        // height: "58px",
        marginTop: "10px",
      },
      [theme.breakpoints.down(481)]: {
        marginTop: "5px",
      },
      [theme.breakpoints.down(430)]: {
        // height: "34px",
        marginTop: "0px",
      },
      [theme.breakpoints.down(381)]: {
        // height: "34px",
      },
      [theme.breakpoints.down(321)]: {
        // height: "34px",
      },
    },
    brandName: {
      fontFamily: "Montserrat",
      fontSize: "16px",
      userSelect: "none",
      lineHeight: "1.25",
      width: "92%",
      fontWeight: "normal",
      overflow: "hidden",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      "-webkit-line-clamp": 1,
      "-webkit-box-orient": "vertical",
      [theme.breakpoints.down(641)]: {
        fontSize: "14px",
        lineHeight: "1.4",
      },
      [theme.breakpoints.down(481)]: {
        fontSize: "12px",
        lineHeight: "1.3",
      },
      [theme.breakpoints.down(421)]: {
        fontSize: "11.5px",
        lineHeight: "1.25",
      },
      [theme.breakpoints.down(401)]: {
        fontSize: "11px",
      },
      [theme.breakpoints.down(381)]: {
        fontSize: "10.5px",
        marginTop: "4px",
        lineHeight: "1.25",
      },
      [theme.breakpoints.down(361)]: {
        fontSize: "10px",
      },
      [theme.breakpoints.down(321)]: {
        fontSize: "8px",
        lineHeight: "1.23",
      },
    },
    productName: {
      fontFamily: "Montserrat",
      fontSize: "18px",
      height: "50px",
      lineHeight: "1.4",
      width: "92%",
      fontWeight: "500",
      overflow: "hidden",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      userSelect: "none",
      "-webkit-line-clamp": 2,
      "-webkit-box-orient": "vertical",
      [theme.breakpoints.down(641)]: {
        fontSize: "16px",
        lineHeight: "1.3",
        height: "40px",
        marginTop: "20px",
      },
      [theme.breakpoints.down(481)]: {
        fontSize: "13.5px",
        marginTop: "5px",
        height: "35px",
        lineHeight: "1.25",
      },
      [theme.breakpoints.down(421)]: {
        marginTop: "5px",
        fontSize: "12.5px",
        height: "31px",
      },
      [theme.breakpoints.down(401)]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down(381)]: {
        fontSize: "12px",
        height: "27px",
        marginTop: "4px",
        lineHeight: "1.1",
      },
      [theme.breakpoints.down(361)]: {
        fontSize: "11.5px",
        height: "25px",
      },
      [theme.breakpoints.down(321)]: {
        fontSize: "10px",
        lineHeight: "1.2",
        height: "24px",
      },
    },
    specialPoints: {
      fontFamily: "Montserrat",
      fontSize: "18px",
      lineHeight: "1.5",
      userSelect: "none",
      fontWeight: "bold",
      color: theme.palette.typography.texts.specialPointsColor,
      [theme.breakpoints.down(641)]: {
        fontSize: "16px",
        lineHeight: "1.56",
      },
      [theme.breakpoints.down(481)]: {
        fontSize: "14px",
        lineHeight: "1.5",
      },
      [theme.breakpoints.down(421)]: {
        fontSize: "13.5px",
      },
      [theme.breakpoints.down(401)]: {
        fontSize: "13px",
      },
      [theme.breakpoints.down(381)]: {
        fontSize: "12.5px",
        lineHeight: "1.5",
      },
      [theme.breakpoints.down(361)]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down(321)]: {
        fontSize: "10px",
        lineHeight: "1.2",
      },
    },
    sellingPoints: {
      fontSize: "16px",
      textDecorationLine: "line-through",
      marginTop: "1.5px",
      paddingLeft: "8px",
      userSelect: "none",
      fontFamily: "Montserrat",
      fontWeight: "500",
      lineHeight: "1.5",
      color: "#7e7e7e",
      [theme.breakpoints.down(641)]: {
        fontSize: "14px",
        lineHeight: "1.56",
        paddingLeft: "2px",
      },
      [theme.breakpoints.down(481)]: {
        fontSize: "12px",
        lineHeight: "1.5",
      },
      [theme.breakpoints.down(430)]: {
        fontSize: "11.5px",
      },
      [theme.breakpoints.down(381)]: {
        fontSize: "11px",
      },
      [theme.breakpoints.down(361)]: {
        fontSize: "10px",
      },
      [theme.breakpoints.down(321)]: {
        fontSize: "8px",
        lineHeight: "1.2",
      },
    },
    root: {
      // width: "calc(100% - 30px)",
      minHeight: "342px",
      [theme.breakpoints.down(641)]: {
        minHeight: "325px",
      },
      [theme.breakpoints.down(481)]: {
        minHeight: "249px",
      },
      [theme.breakpoints.down(430)]: {
        minHeight: "216px",
      },
      [theme.breakpoints.down(380)]: {
        minHeight: "202px",
      },
      [theme.breakpoints.down(361)]: {
        minHeight: "192px",
      },
      [theme.breakpoints.down(321)]: {
        minHeight: "174px",
      },
      "& .MuiCardActionArea-focusHighlight": {
        opacity: "0 !important",
      },
    },
    cardContent: {
      padding: 0,
      height: "172px",
      paddingLeft: "20px",
      [theme.breakpoints.down(641)]: {
        height: "152px",
        paddingLeft: "18px",
      },
      [theme.breakpoints.down(481)]: {
        height: "114px",
        paddingLeft: "14px",
      },
      [theme.breakpoints.down(430)]: {
        height: "95px",
        paddingLeft: "10px",
      },
      [theme.breakpoints.down(381)]: {
        height: "89px",
        paddingLeft: "10px",
      },
      [theme.breakpoints.down(321)]: {
        height: "74px",
        paddingLeft: "9px",
      },
    },
    container: {},
    paddingGrid: {
      padding: "4px",
    },
    reedemBoxes: {
      margin: "0",
      padding: "0",
      display: "block",
      width: "100%",
      position: "relative",
      textAlign: "left",
    },
    mainDiv: {
      backgroundColor: theme.palette.common.globalBackground,
      overflow: "hidden",
    },
    related_products: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "#7e7e7e",
      userSelect: "none",
      position: "absolute",
      bottom: "36px",
      right: "6px",
      fontSize: "18px",
      width: "63px",
      height: "41px",
      border: "1px solid  #e4e4e4",
      borderRadius: "4px",
      textAlign: "center",
      // letterSpacing: "2px",
      paddingLeft: "1px",
      [theme.breakpoints.down(641)]: {
        fontSize: "16px",
        width: "57px",
        height: "37px",
        bottom: "25px",
        right: "8px",
      },
      [theme.breakpoints.down(481)]: {
        fontSize: "12px",
        width: "43px",
        height: "28px",
      },
      [theme.breakpoints.down(421)]: {
        fontSize: "11.5px",
        width: "32px",
        height: "21px",
        bottom: "16px",
        right: "5px",
      },
      [theme.breakpoints.down(401)]: {
        fontSize: "11px",
        width: "32px",
        height: "21px",
        bottom: "16px",
      },
      [theme.breakpoints.down(381)]: {
        fontSize: "10.5px",
        width: "32px",
        height: "22px",
      },
      [theme.breakpoints.down(361)]: {
        fontSize: "10px",
        right: "6px",
        width: "32px",
        height: "21px",
        bottom: "16px",
      },
      [theme.breakpoints.down(321)]: {
        fontSize: "8px",
        width: "29px",
        height: "19px",
        bottom: "13px",
      },
    },
    noRecord: {
      fontSize: "1.5rem",
      padding: "30px 15px",
      fontWeight: 400,
      userSelect: "none",
      // color:'#F7942F',
      color: "black",
      opacity: 0.4,
      margin: "1rem auto",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1rem",
      },
    },
    loaderProgress: {
      position: "absolute",
      zIndex: 999,
      top: "60%",
      left: "48%",
      marginTop: -12,
      backgroundColor: "transparent",
      boxShadow: "none",
      marginLeft: -12,
      color: primaryOrange,
    },
    related_products_box: {
      width: "45px",
      // padding: "2px 5px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "4px",
      border: "1px solid #e4e4e4",
      cursor: "pointer",
    },
    ovrflhidden: {
      overflow: "hidden",
    },
    new_root: {
      height: "100%",
      backgroundColor: globalBackground,
      // marginBottom: '86px',
      // [theme.breakpoints.down('641')]: {
      //   marginBottom: '82px',
      // },
      // [theme.breakpoints.down('481')]: {
      //   marginBottom: '64px',
      // },
      // [theme.breakpoints.down('421')]: {
      //   marginBottom: '54px',
      // },
      // [theme.breakpoints.down('381')]: {
      //   marginBottom: '50px',
      // },
      // [theme.breakpoints.down('361')]: {
      //   marginBottom: '48px',
      // },
      // [theme.breakpoints.down('321')]: {
      //   marginBottom: '43px',
      // }
    },
  };
};

class ProductList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDetails: false,
      details: {},
    };
  }

  roundOff = (voucherValue) => {
    return parseFloat(voucherValue) % 1 !== 0
      ? parseFloat(voucherValue).toFixed(2)
      : Math.floor(voucherValue);
  };

  viewProductDetails = async (details) => {
    this.props.history.push({
      pathname: "/product/" + details.id,
      state: {
        details: details,
      },
    });
  };

  convertDate = (expiryDate) => {
    const moment = require("moment");
    const today = moment(expiryDate);
    return today.format("Do MMM'YY");
  };

  getItemPrice = (specialPrice, startDate, endDate, sellingPrice) => {
    const moment = require("moment");
    const currentDate = moment().format("YYYY-MM-DD");
    const { classes } = this.props;
    if (specialPrice === "0.0000") {
      return [
        {
          classKey: classes.specialPoints,
          value:
            parseFloat(sellingPrice) % 1 !== 0
              ? parseFloat(sellingPrice).toFixed(2)
              : Math.floor(sellingPrice),
          xs: 11,
        },
      ];
    } else if (endDate && endDate === currentDate) {
      return [
        {
          classKey: classes.specialPoints,
          value:
            parseFloat(specialPrice) % 1 !== 0
              ? parseFloat(specialPrice).toFixed(2)
              : Math.floor(specialPrice),
          xs: 6,
        },
        {
          classKey: classes.sellingPoints,
          value:
            parseFloat(sellingPrice) % 1 !== 0
              ? parseFloat(sellingPrice).toFixed(2)
              : Math.floor(sellingPrice),
          xs: 5,
        },
      ];
    } else if (startDate && startDate === currentDate) {
      return [
        {
          classKey: classes.specialPoints,
          value:
            parseFloat(specialPrice) % 1 !== 0
              ? parseFloat(specialPrice).toFixed(2)
              : Math.floor(specialPrice),
          xs: 6,
        },
        {
          classKey: classes.sellingPoints,
          value:
            parseFloat(sellingPrice) % 1 !== 0
              ? parseFloat(sellingPrice).toFixed(2)
              : Math.floor(sellingPrice),
          xs: 5,
        },
      ];
    } else if (moment(currentDate).isBetween(startDate, endDate)) {
      return [
        {
          classKey: classes.specialPoints,
          value:
            parseFloat(specialPrice) % 1 !== 0
              ? parseFloat(specialPrice).toFixed(2)
              : Math.floor(specialPrice),
          xs: 6,
        },
        {
          classKey: classes.sellingPoints,
          value:
            parseFloat(sellingPrice) % 1 !== 0
              ? parseFloat(sellingPrice).toFixed(2)
              : Math.floor(sellingPrice),
          xs: 5,
        },
      ];
    } else {
      return [
        {
          classKey: classes.specialPoints,
          value:
            parseFloat(sellingPrice) % 1 !== 0
              ? parseFloat(sellingPrice).toFixed(2)
              : Math.floor(sellingPrice),
          xs: 11,
        },
      ];
    }
  };

  render() {
    const { classes, isFilterDrawerOpen, list, pointsDynamicName } = this.props;

    return (
      <div className={classes.new_root}>
        <RedeemSubHeader
          isSubHeader={this.props.isSubHeader}
          isListView={this.props.isListView}
          isBottomDrawer={this.props.isBottomDrawer}
          sortProducts={this.props.sortProducts}
          merchantDetailsResponse={this.props.merchantDetailsResponse}
          openListView={this.props.openListView}
          openFilterDrawer={this.props.openFilterDrawer}
          openBottomDrawer={this.props.openBottomDrawer}
        />
        <div
          className={classnames(classes.fab1, {
            [classes.ovrflhidden]: isFilterDrawerOpen,
          })}
        >
          <Search
            onSearchTextChange={this.props.onSearchText}
            searchText={this.props.searchText}
            className="p15"
            paddingBottom="pB10"
            resetSearch={this.props.resetSearch}
            searchCall={this.props.searchCall}
          />
          <Grid container>
            {this.props.isLoader && (
              <CircularProgress size={40} className={classes.loaderProgress} />
            )}
            {list &&
              list.length > 0 &&
              list.map((elem) => (
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={4}
                  key={elem.temp_id}
                  className={classes.paddingGrid}
                  onClick={() => this.viewProductDetails(elem)}
                >
                  <Card className={classes.root}>
                    <CardActionArea className={classes.root}>
                      <CardMedia
                        component="img"
                        alt={elem.product_name}
                        image={
                          elem.image_name ? elem.image_name : default_product
                        }
                        height="100%"
                        width="100%"
                        className={classes.productImage}
                        title={elem.product_name}
                      />
                      <CardContent className={classes.cardContent}>
                        {elem.display_custom_text_or_date === "1" &&
                          (elem.Listexpiry_date === "" ||
                          elem.expiry_date === null ||
                          elem.expiry_date === "Invalid date" ? (
                            <div className={classes.nullExpiryDate}></div>
                          ) : (
                            <Typography className={classes.expiryDate}>
                              Expires{" "}
                              {this.convertDate(elem.expiry_date) ===
                              "Invalid date"
                                ? ""
                                : this.convertDate(elem.expiry_date)}
                            </Typography>
                          ))}

                        {elem.display_custom_text_or_date === "2" &&
                          elem.custom_text && (
                            <Typography className={classes.expiryDate}>
                              {elem.custom_text}
                            </Typography>
                          )}
                        <Grid>
                          <Typography
                            variant="h6"
                            className={classes.brandName}
                          >
                            {elem.brand_name}
                          </Typography>
                          <Typography
                            variant="h6"
                            className={classes.productName}
                          >
                            {elem.product_name}
                          </Typography>
                        </Grid>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            paddingRight: "10px",
                            marginTop: "3px",
                          }}
                        >
                          <Grid container className={classes.pointsDisplay}>
                            {this.getItemPrice(
                              elem.special_price_points_value,
                              elem.special_price_start_date,
                              elem.special_price_end_date,
                              elem.selling_price_points_value
                            ).map((item, index) => {
                              return (
                                <Grid
                                  item
                                  xs={item.xs}
                                  key={index}
                                  className={item.classKey}
                                >
                                  {item.value}{" "}
                                  {pointsDynamicName
                                    ? pointsDynamicName
                                    : this.props.merchantDetailsResponse
                                    ? this.props.merchantDetailsResponse
                                        .points_unit_short_name
                                    : "P"}
                                </Grid>
                              );
                            })}
                          </Grid>
                          {elem.related_products_count === 0 ? null : (
                            <Grid
                              item
                              xs={1}
                              onClick={(e) =>
                                this.props.toggleRelatedProducts(
                                  e,
                                  elem.product_id,
                                  elem.id
                                )
                              }
                              // className={classes.related_products_box}
                            >
                              <Typography className={classes.related_products}>
                                {"+ " + elem.related_products_count}
                              </Typography>
                            </Grid>
                          )}
                        </div>
                        <div className={classes.location_box}>
                          {elem.location_names &&
                          elem.locations&& (
                            <Typography className={classes.location}>
                              {elem.locations}
                              {elem.location_names.split(",").length - 1 !== 0 &&
                                ` +${elem.location_names.split(",").length - 1}`}
                            </Typography>
                          )}
                        </div>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              ))}
            {this.props.noRecords === "No record found" && (
              <NoRecordFound message={"No product found"} />
            )}
          </Grid>
        </div>
      </div>
    );
  }
}

export default withRouter(withStyles(styles, { withTheme: true })(ProductList));

ProductList.defaultProps = {
  isLoader: false,
};
