import React, { Component } from "react";
import no_found from "../../assets/icons/redeem/not_found.svg";
import CMBGicon from "../../assets/icons/comingSoon/coming-soon-bg.svg";

import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { color } from '../UI/Theme';

const styles = (theme) => {
  return {
    coming_soon: {
      textAlign: "center",
      color: color,
      fontSize: "30px",
      [theme.breakpoints.down("641")]: {
        fontSize: "24px",
      },
      [theme.breakpoints.down("481")]: {
        fontSize: "20px",
      },
      [theme.breakpoints.down("421")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down("401")]: {
        fontSize: "15px",
      },
      [theme.breakpoints.down("381")]: {
        fontSize: "14.5px",
      },
      [theme.breakpoints.down("361")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down("321")]: {
        fontSize: "12px",
      },
    },
    coming_soon_img: {
      width: "100%",
      height: "100%",
      display: "inline-block",
      transition: ".12s",
      "-webkit-mask-size": "cover",
      "mask-size ": "cover",
      objectPosition: "center",
      objectFit: "contain",
    },
    bgCss: {
      background: `url(..${CMBGicon}) no-repeat center / contain`,
      display: "flex",
      justifyContent: "center",
    },
    iconCss: {
      width: "40%",
      backgroundColor: theme.palette.primary.main,
      " -webkit-mask-image": `url(..${no_found})`,
      "mask-image": `url(..${no_found})`,
      mask: `url(..${no_found}) no-repeat center / contain`,
      "-webkit-mask": `url(..${no_found}) no-repeat center / contain`,
    },
    iconShrinkCss: {
      width: "25%",
      backgroundColor: theme.palette.primary.main,
      " -webkit-mask-image": `url(..${no_found})`,
      "mask-image": `url(..${no_found})`,
      mask: `url(..${no_found}) no-repeat center / contain`,
      "-webkit-mask": `url(..${no_found}) no-repeat center / contain`,
    },
  };
};

class NoRecordFound extends Component {
  render() {
    const { classes, message, height, shrinkImgSize } = this.props;

    return (
      <div style={{ width: "100%" }}>
        <div
          style={{ width: "100%", height: height || "110px", overflow: "hidden" }}
          className={classes.bgCss}
        >
            <span className={classes.coming_soon_img + " " +  shrinkImgSize ? classes.iconShrinkCss : classes.iconCss} />
        </div>
        <Typography className={classes.coming_soon}>{message}</Typography>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(NoRecordFound);
